<template>
  <div class="auth-wrapper px-2">
    <div class="auth-inner py-2">
      <div class="brand">
        <img
          :src="imgUrl"
          class="brand-img"
          alt="Payment Info"
        >
      </div>

      <!-- Login v1 -->
      <b-card
        class="mb-0"
        title="Thank you. Information was saved successfully."
      >
        <b-button
          size="sm"
          variant="primary"
          :to="{ name: 'auth-register' }"
        >Become a member here.</b-button>
        <span class="mx-2">Or</span>
        <b-button
          size="sm"
          :to="{ name: 'auth-login' }"
        >Login to your account.</b-button>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  BButton, BCard,
} from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    // BSV
    BButton,
    BCard,
  },
  data() {
    return {
      isLoading: false,
      // eslint-disable-next-line global-require
      sideImg: require('@/assets/PAVRISK.png'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties,global-require
        this.sideImg = require('@/assets/PAVRISK.png')
        // this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.brand {
  padding: 10px;
  width: 50%;
}
.brand-img {
  width: 140px;
  margin-left: auto;
  margin-right: auto;
}
</style>
